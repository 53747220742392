<template lang="pug">
    .bdi-layouts
        aside.bdi-layouts__sidebar
            router-link(to="/").bdi-sidebar__logo.logo-ai

            .bdi-sidebar__menu
                .back-bdi.flex.mb-4
                    b-icon.mr-2(name="chevron-left")
                    router-link.block(to="/bdi/organisasi") Kumpulan BDI

                .detail-sidebar
                    router-link.block(:to="'/organisasi/'+ id +'/detail/profil'") Profil
                    router-link.block(:to="'/organisasi/'+ id +'/detail/struktur'") Struktur
                    router-link.block(:to="'/organisasi/'+ id +'/detail/motif-agenda'") Motif Agenda
                    router-link.block(:to="'/organisasi/'+ id +'/detail/aktivitas'") Aktivitas
                    //- router-link.block(:to="'/organisasi/'+ id +'/detail/export-pdf'") Preview PDF
                    .box-export(v-if="status_detail.status !== 'loading' && status_related_organizations.status !== 'loading' && status_motive_agenda.status !== 'loading'")
                        .bt-export
                            b-button(text_icon_left="file" text="Export PDF" kind="tertiary" type="button" size="small" @click.native="generateReportPdf()")

                    //- router-link.block(:to="'/organisasi/'+ id +'/detail/berita-terkait'") Berita Terkait
                    //- router-link.block(to="/organisasi/sikap") Sikap
                    //- router-link.block(to="/organisasi/prilaku") Prilaku

                    vue-html2pdf(
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="false"
                        :preview-modal="false"
                        :paginate-elements-by-height="1400"
                        :filename="'Ringkasan Data'+ (detail.name ? ' - '+ detail.name : '')"
                        :pdf-quality="2"
                        :manual-pagination="true"
                        pdf-format="a4"
                        pdf-orientation="portrait"
                        pdf-content-width="800px"

                        ref="html2Pdf"
                        @beforeDownload="beforeDownload($event)"
                        :margin="[30, 30]"
                    )
                        //- @progress="onProgress($event)"
                        //- @hasStartedGeneration="hasStartedGeneration()"
                        //- @hasGenerated="hasGenerated($event)"
                        section(slot="pdf-content")
                            ExportPdf

            .bdi-sidebar__more
                .floating-more(@click="toggleMore()" v-closable-menu-sidebar-general-bdi-organization="{exclude: ['menuSidebarGeneralActiveBdiOrganization'], handler: 'closeMenuGeneralSidebar'}")
                    b-button(text="Menu Lainnya" text_icon_left="more-horizontal" kind="tertiary")

        .bdi-layouts__content
            router-view(:id="id")

        .card-menu-popup.card-menu-popup--more(:class="{'card-menu-popup--show' : openMore}" ref="menuSidebarGeneralActiveBdiOrganization")
           MenuSidebarGeneral
</template>

<script>
import { mapState } from 'vuex';
import MenuSidebarGeneral from '@/components/MenuSidebarGeneral';
import VueHtml2pdf from 'vue-html2pdf';
import ExportPdf from './pdf/Index.vue';
let handleOutsideClick;

export default {
    name: 'BdiOrganizationPage',
    components: {
        MenuSidebarGeneral,
        VueHtml2pdf,
        ExportPdf,
    },
    directives: {
        'closable-menu-sidebar-general-bdi-organization': {
            bind (el, binding, vnode) {
                handleOutsideClick = (e) => {
                    e.stopPropagation();
                    const { handler, exclude } = binding.value;
                    let clickedOnExcludedEl = false;

                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const excludedEl = vnode.context.$refs[refName];
                            if (excludedEl)
                                clickedOnExcludedEl = excludedEl.contains(e.target);
                        }
                    })

                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        vnode.context[handler]();
                    }
                }
                document.addEventListener('click', handleOutsideClick);
                document.addEventListener('touchstart', handleOutsideClick);
            },
            unbind () {
                document.removeEventListener('click', handleOutsideClick);
                document.removeEventListener('touchstart', handleOutsideClick);
            }
        }
    },
    data() {
        return {
            openMore: false,
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('organization', [
            'status_detail',
            'detail',
            'status_related_organizations',
            'status_motive_agenda',
        ]),
    },
    watch: {
        id() {},
    },
    methods: {
        toggleMore() {
            this.openMore = !this.openMore
        },
        closeMenuGeneralSidebar() {
            this.openMore = false;
        },
        async getData() {
            await this.$store.dispatch('organization/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                    } else {
                        Event.$emit('error');
                    }
                });
        },
        generateReportPdf() {
            this.$refs.html2Pdf.generatePdf();
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    if (i === 1 || i === totalPages) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        // pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))

                        // top
                        if (i === 1) pdf.text('Dokumen Rahasia Milik Negara', (pdf.internal.pageSize.getWidth() * 0.73), (pdf.internal.pageSize.getHeight() - 11.33));

                        // bottom
                        if (i === totalPages) pdf.text('Dokumen Rahasia Milik Negara', (pdf.internal.pageSize.getWidth() * 0.73), (pdf.internal.pageSize.getHeight() - 0.3));
                    }
                }
            }).save();
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // console.log(to);
            if (to.matched && to.matched[0] && to.matched[0].name === 'OrganizationPage') {
                vm.getData();
            }
        });
    },
}
</script>

<style lang="sass">
.box-export
    @apply pl-8 pt-3

.bt-export
    @apply border-t pt-4
    .btn--modifier
        padding: 8px 16px
        .b-icon--left
            margin-right: 4px
        span
            line-height: 100%
</style>
